<template>
  <v-dialog v-model="dialog" :persistent="true" :max-width="!usuarioContemAlteracao ? '40%' : '60%'">
    <template>
      <v-card>
        <v-card-title v-if="!usuarioContemAlteracao" class="text-left pb-6">{{ `${$tc('title.ultima_edicao_usuario', 1)}` }}</v-card-title>
        <v-card-title v-if="usuarioContemAlteracao" class="text-left pb-6">{{ `${$tc('title.usuario_realiza_cadastro_alteracao', 1)}: ` }}</v-card-title>
        <v-card-text v-if="usuarioContemAlteracao">
          <v-row>
            <v-col v-for="item in usuarioRealizado" :key="item.label" v-show="item.value" cols="6" ><span class="font-weight-bold">{{ `${item.label}:  ` }}</span>{{ item.value }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-title v-if="usuarioContemAlteracao" class="text-left pb-6">{{ `${$tc('title.usuario_cadastrado_alterado', 1)}: ` }}</v-card-title>
        <v-card-text v-if="usuarioContemAlteracao">
          <v-row>
            <v-col v-for="item in usuarioAlterado" :key="item.label" v-show="item.value" cols="6"><span class="font-weight-bold">{{ `${item.label}:  ` }}</span>{{ item.value }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click.native="close">{{ $t('label.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDadosUltimaAlteracaoUsuario',
  props: {
  },
  data() {
    return {
      usuarioResource: this.$api.usuario(this.$resource),
      dadosUsuario: {},
      dialog: false,
      usuarioRealizado: [],
      usuarioAlterado: [],
      usuarioContemAlteracao: false,
    };
  },
  methods: {
    open(dados) {
      this.buscaUltimaAlteracao(dados.idUsuario);
    },
    close() {
      this.idUsuario = null;
      this.usuarioRealizado = [];
      this.usuarioAlterado = [];
      this.usuarioContemAlteracao = false;
      this.dialog = false;
    },
    cancelar() {
      this.close();
      this.$emit('cancelar');
    },
    buscaUltimaAlteracao(idUsuario) {
      this.usuarioResource.buscaUltimaAlteracao(({ idUsuario }))
        .then((res) => {
          this.dadosUsuario = res.body;
          this.usuarioContemAlteracao = this.verificaCamposAlteracao();
          if (this.usuarioContemAlteracao) {
            this.alteraCamposVazios();
          }
          this.preencheCampos();
          this.dialog = true;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    verificaCamposAlteracao() {
      return this.dadosUsuario.nome_de
     || this.dadosUsuario.nome_para
     || this.dadosUsuario.sobrenome_de
     || this.dadosUsuario.sobrenome_para
     || this.dadosUsuario.divisao_de
     || this.dadosUsuario.divisao_para
     || this.dadosUsuario.perfil_de
     || this.dadosUsuario.perfil_para
     || this.dadosUsuario.email_de
     || this.dadosUsuario.email_para
     || this.dadosUsuario.cargo_de
     || this.dadosUsuario.cargo_para
     || this.dadosUsuario.documento_de
     || this.dadosUsuario.documento_para
     || this.dadosUsuario.ativo_de
     || this.dadosUsuario.ativo_para
     || this.dadosUsuario.recebe_email_de
     || this.dadosUsuario.recebe_email_para
     || this.dadosUsuario.fornecedor_master_de
     || this.dadosUsuario.fornecedor_master_para
     || this.dadosUsuario.grupo_fornecedor_de
     || this.dadosUsuario.grupo_fornecedor_para
     || this.dadosUsuario.anexo_de
     || this.dadosUsuario.anexo_para
     || this.dadosUsuario.representacao_conjunto_de
     || this.dadosUsuario.representacao_conjunto_para
     || this.dadosUsuario.aprovador_anexo_comercial_de
     || this.dadosUsuario.aprovador_anexo_comercial_para
     || this.dadosUsuario.dta_inicio_vigencia_de
     || this.dadosUsuario.dta_inicio_vigencia_para
     || this.dadosUsuario.dta_fim_vigencia_de
     || this.dadosUsuario.dta_fim_vigencia_para;
    },
    alteraCamposVazios() {
      const campos = [
        'nome_de',
        'nome_para',
        'sobrenome_de',
        'sobrenome_para',
        'perfil_de',
        'perfil_para',
        'divisao_de',
        'divisao_para',
        'email_de',
        'email_para',
        'cargo_de',
        'cargo_para',
        'documento_de',
        'documento_para',
        'ativo_de',
        'ativo_para',
        'recebe_email_de',
        'recebe_email_para',
        'fornecedor_master_de',
        'fornecedor_master_para',
        'grupo_fornecedor_de',
        'grupo_fornecedor_para',
        'anexo_de',
        'anexo_para',
        'representacao_conjunto_de',
        'representacao_conjunto_para',
        'aprovador_anexo_comercial_de',
        'aprovador_anexo_comercial_para',
        'dta_inicio_vigencia_de',
        'dta_inicio_vigencia_para',
        'dta_fim_vigencia_de',
        'dta_fim_vigencia_para',
      ];
      campos.forEach((campo) => {
        if (this.dadosUsuario[campo] === '') {
          this.dadosUsuario[campo] = 'Campo sem valor';
        }
      });
    },
    preencheCampos() {
      this.usuarioRealizado.push(
        { label: this.$tc('label.tipo_alteracao', 1), value: this.dadosUsuario && this.dadosUsuario.tipo_alteracao ? this.dadosUsuario.tipo_alteracao : null },
        { label: this.$tc('label.tipo_usuario', 1), value: this.dadosUsuario && this.dadosUsuario.tipo_usuario ? this.dadosUsuario.tipo_usuario : null },
        { label: this.$tc('label.grupo_fornecedor', 1), value: this.dadosUsuario && this.dadosUsuario.grupo_fornecedor ? this.dadosUsuario.grupo_fornecedor : null },
        { label: this.$tc('label.acao_efetuada', 1), value: this.dadosUsuario && this.dadosUsuario.acao_efetuada ? this.dadosUsuario.acao_efetuada : null },
        { label: this.$tc('label.usuario', 1), value: this.dadosUsuario && this.dadosUsuario.usuario ? this.dadosUsuario.usuario : null },
        { label: this.$tc('label.data_alteracao', 1), value: this.dadosUsuario && this.dadosUsuario.data_alteracao ? this.dadosUsuario.data_alteracao : null },
      );
      this.usuarioAlterado.push(
        { label: this.$tc('label.usuario_alterado', 1), value: this.dadosUsuario && this.dadosUsuario.usuario_alterado ? this.dadosUsuario.usuario_alterado : null },
        { label: this.$tc('label.cod_usuario_alterado', 1), value: this.dadosUsuario && this.dadosUsuario.cod_usuario_alterado ? this.dadosUsuario.cod_usuario_alterado : null },
        { label: this.$tc('label.nome_de', 1), value: this.dadosUsuario && this.dadosUsuario.nome_de ? this.dadosUsuario.nome_de : null },
        { label: this.$tc('label.nome_para', 1), value: this.dadosUsuario && this.dadosUsuario.nome_para ? this.dadosUsuario.nome_para : null },
        { label: this.$tc('label.sobrenome_de', 1), value: this.dadosUsuario && this.dadosUsuario.sobrenome_de ? this.dadosUsuario.sobrenome_de : null },
        { label: this.$tc('label.sobrenome_para', 1), value: this.dadosUsuario && this.dadosUsuario.sobrenome_para ? this.dadosUsuario.sobrenome_para : null },
        { label: this.$tc('label.perfil_de', 1), value: this.dadosUsuario && this.dadosUsuario.perfil_de ? this.dadosUsuario.perfil_de : null },
        { label: this.$tc('label.perfil_para', 1), value: this.dadosUsuario && this.dadosUsuario.perfil_para ? this.dadosUsuario.perfil_para : null },
        { label: this.$tc('label.divisao_de', 1), value: this.dadosUsuario && this.dadosUsuario.divisao_de ? this.dadosUsuario.divisao_de : null },
        { label: this.$tc('label.divisao_para', 1), value: this.dadosUsuario && this.dadosUsuario.divisao_para ? this.dadosUsuario.divisao_para : null },
        { label: this.$tc('label.email_de', 1), value: this.dadosUsuario && this.dadosUsuario.email_de ? this.dadosUsuario.email_de : null },
        { label: this.$tc('label.email_para', 1), value: this.dadosUsuario && this.dadosUsuario.email_para ? this.dadosUsuario.email_para : null },
        { label: this.$tc('label.cargo_de', 1), value: this.dadosUsuario && this.dadosUsuario.cargo_de ? this.dadosUsuario.cargo_de : null },
        { label: this.$tc('label.cargo_para', 1), value: this.dadosUsuario && this.dadosUsuario.cargo_para ? this.dadosUsuario.cargo_para : null },
        { label: this.$tc('label.documento_de', 1), value: this.dadosUsuario && this.dadosUsuario.documento_de ? this.dadosUsuario.documento_de : null },
        { label: this.$tc('label.documento_para', 1), value: this.dadosUsuario && this.dadosUsuario.documento_para ? this.dadosUsuario.documento_para : null },
        { label: this.$tc('label.ativo_de', 1), value: this.dadosUsuario && this.dadosUsuario.ativo_de ? this.dadosUsuario.ativo_de : null },
        { label: this.$tc('label.ativo_para', 1), value: this.dadosUsuario && this.dadosUsuario.ativo_para ? this.dadosUsuario.ativo_para : null },
        { label: this.$tc('label.recebe_email_de', 1), value: this.dadosUsuario && this.dadosUsuario.recebe_email_de ? this.dadosUsuario.recebe_email_de : null },
        { label: this.$tc('label.recebe_email_para', 1), value: this.dadosUsuario && this.dadosUsuario.recebe_email_para ? this.dadosUsuario.recebe_email_para : null },
        { label: this.$tc('label.fornecedor_master_de', 1), value: this.dadosUsuario && this.dadosUsuario.fornecedor_master_de ? this.dadosUsuario.fornecedor_master_de : null },
        { label: this.$tc('label.fornecedor_master_para', 1), value: this.dadosUsuario && this.dadosUsuario.fornecedor_master_para ? this.dadosUsuario.fornecedor_master_para : null },
        { label: this.$tc('label.grupo_fornecedor_de', 1), value: this.dadosUsuario && this.dadosUsuario.grupo_fornecedor_de ? this.dadosUsuario.grupo_fornecedor_de : null },
        { label: this.$tc('label.grupo_fornecedor_para', 1), value: this.dadosUsuario && this.dadosUsuario.grupo_fornecedor_para ? this.dadosUsuario.grupo_fornecedor_para : null },
        { label: this.$tc('label.fornecedor_master_atual', 1), value: this.dadosUsuario && this.dadosUsuario.fornecedor_master_atual ? this.dadosUsuario.fornecedor_master_atual : null },
        { label: this.$tc('label.anexo_de', 1), value: this.dadosUsuario && this.dadosUsuario.anexo_de ? this.dadosUsuario.anexo_de : null },
        { label: this.$tc('label.anexo_para', 1), value: this.dadosUsuario && this.dadosUsuario.anexo_para ? this.dadosUsuario.anexo_para : null },
        { label: this.$tc('label.representacao_conjunto_de', 1), value: this.dadosUsuario && this.dadosUsuario.representacao_conjunto_de ? this.dadosUsuario.representacao_conjunto_de : null },
        { label: this.$tc('label.representacao_conjunto_para', 1), value: this.dadosUsuario && this.dadosUsuario.representacao_conjunto_para ? this.dadosUsuario.representacao_conjunto_para : null },
        { label: this.$tc('label.aprovador_anexo_comercial_de', 1), value: this.dadosUsuario && this.dadosUsuario.aprovador_anexo_comercial_de ? this.dadosUsuario.aprovador_anexo_comercial_de : null },
        { label: this.$tc('label.aprovador_anexo_comercial_para', 1), value: this.dadosUsuario && this.dadosUsuario.aprovador_anexo_comercial_para ? this.dadosUsuario.aprovador_anexo_comercial_para : null },
        { label: this.$tc('label.dta_inicio_vigencia_de', 1), value: this.dadosUsuario && this.dadosUsuario.dta_inicio_vigencia_de ? this.dadosUsuario.dta_inicio_vigencia_de : null },
        { label: this.$tc('label.dta_inicio_vigencia_para', 1), value: this.dadosUsuario && this.dadosUsuario.dta_inicio_vigencia_para ? this.dadosUsuario.dta_inicio_vigencia_para : null },
        { label: this.$tc('label.dta_fim_vigencia_de', 1), value: this.dadosUsuario && this.dadosUsuario.dta_fim_vigencia_de ? this.dadosUsuario.dta_fim_vigencia_de : null },
        { label: this.$tc('label.dta_fim_vigencia_para', 1), value: this.dadosUsuario && this.dadosUsuario.dta_fim_vigencia_para ? this.dadosUsuario.dta_fim_vigencia_para : null },
      );
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
