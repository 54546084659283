<template lang="html">
  <div tabindex="100" @keydown.up.prevent="onArrowUpPress" @keydown.down.prevent="onArrowDownPress">
    <usuario-fornecedor-filtros
    ref="filtros"
    @UsuarioFornecedorFiltros__AplicaFiltros="aplicaFiltros">
    </usuario-fornecedor-filtros>
    <v-card class="card-size">
      <v-card-title>
        <h2 class="headline mb-0" v-text="title">
        </h2>
      </v-card-title>
      <v-data-table :headers="headers" :items="registros" :options.sync="pagination" :item-class="onItemClass"
        :server-items-length="totalPage" :no-data-text="$t('label.tabela_sem_conteudo')" :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="podeEditar(item)" @click="edit(item)">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.editar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeVisualizar(item)" @click="ver(item)">
                <v-list-item-action>
                  <v-icon>dehaze</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.visualizar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canAccessCRUD || isUsuarioLogadoExternoMaster" @click="redefinirSenha(item)">
                <v-list-item-action>
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.redefinir_senha') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="buscaDadosUltimaAlteracao(item)">
                <v-list-item-action>
                  <v-icon>app_registration</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.ultima_alteracao') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="buscaRelatorioLogUsuarioPorId(item)">
                <v-list-item-action>
                  <v-icon>get_app</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.exportar_log') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="acessoRedefinirSenhaPadraoTradelinks" @click="redefinirSenhaPadraoTradelinks(item)">
                <v-list-item-action>
                  <v-icon>lock</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.redefinir_senha_padrao') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeAprovarReprovarRepresentanteLegal(item.statusFluxo, item.indPossuiAnexos)"
                @click="openModalJustificativaAprovacao(item, 'APROVADO', $t('label.aprovar'))">
                <v-list-item-action>
                  <v-icon>thumb_up</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.aprovar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="podeAprovarReprovarRepresentanteLegal(item.statusFluxo, item.indPossuiAnexos)"
                @click="openModalJustificativaAprovacao(item, 'REPROVADO', $t('label.reprovar'))">
                <v-list-item-action>
                  <v-icon>thumb_down</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('label.reprovar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="mostrarAcompanhamento(item)" @click="abrirAcompanhamento(item.idUsuario, item)">
                <v-list-item-action>
                  <v-icon>assignment_turned_in</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="limparWorkspaceFiltros(item.id)">
                <v-list-item-action>
                  <v-icon>filter_alt_off</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.limpar_filtros', 1) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.icone_expiracao="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }" v-if="item.exibeIconeAviso && (item.dataVigenciaExpirada || item.dataVigenciaAExpirar)">
              <v-icon
              v-on="on"
              color="orange darken-1"
              dark>
                report_problem
              </v-icon>
            </template>
            <span>{{ item.dataVigenciaExpirada? $t('message.vigencia_expirada_usuario', { dataVigenciaExpirada : formatarData(item.dtaFimVigencia) })
                  : $t('message.alerta_termino_fim_vigencia_usuario', { dataFimVigencia : formatarData(item.dtaFimVigencia) }) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.nome_sobrenome="{ item }">
          {{ item.nomeSobrenome }}
        </template>
        <template v-slot:item.nom_usuario="{ item }">
          {{ item.nomUsuario }}
        </template>
        <template v-slot:item.nom_perfil="{ item }">
          {{ item.nomPerfil }}
        </template>
        <template v-slot:item.nom_estrutura_usuario="{ item }" v-if="isUsuarioLogadoFuncionario">
          {{ item.nomEstruturaUsuario }}
        </template>
        <template v-slot:item.grupofornecedor="{ item }">
          {{ montaTexto(item.grupofornecedor) }}
        </template>
        <template v-slot:item.indUsuarioExternoMaster="{ item }">
          <span>{{ item.indUsuarioExternoMaster ?
            $t('label.tipos_fornecedor.representante_legal') : $t('label.fornecedor_master') }}
          </span>
          <v-tooltip bottom v-if="exibirIconeRepresentacaoConjuntoNaoAprovador(item)">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="secondary lighten-2">
                group
              </v-icon>
            </template>
            <span>{{$t('label.representacao_conjunto')}}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="exibirIconeRepresentacaoConjuntoAprovador(item)">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green">
                group
              </v-icon>
            </template>
            <span>{{$t('label.representacao_conjunto_aprovador_anexo_comercial')}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.indProcurador="{ item }">
          <span>{{ item.indProcurador ? $t('label.sim') : '' }}</span>
        </template>
        <template v-if="podeVisualizarAnexos || podeCadastrarAnexos" v-slot:item.indPossuiAnexos="{ item }">
          <v-btn v-if="item.indPossuiAnexos" icon @click="abrirModalAnexos(item)">
            <v-icon>visibility</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.statusFluxo="{ item }">
          <status v-if="item.statusFluxo" :status="item.statusFluxo" :tooltip="item.statusFluxo"></status>
        </template>
      </v-data-table>
      <usuario-anexos-dialog ref="usuarioAnexosDialog" :pode-visualizar-anexos="podeVisualizarAnexos"
        :pode-cadastrar-anexos="podeCadastrarAnexos">
      </usuario-anexos-dialog>
    </v-card>
    <modal-dados-ultima-alteracao-usuario ref="modalDadosUltimaAlteracaoUsuario">
    </modal-dados-ultima-alteracao-usuario>
    <usuario-aprovacao-dialog ref="modalJustificativaAprovacao" :title="titleJustificativaModal"
      :obrigatorio="statusAprovacaoUsuario !== 'APROVADO'" @Afirmativo="aprovarReprovarUsuario">
    </usuario-aprovacao-dialog>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'REPRESENTANTE_LEGAL'"
    >
    </acompanhamento>
    <v-dialog v-model="dialogAcompanhamentoSemFluxo" max-width="600">
      <v-card>
        <v-card-title class="headline"> {{ $t('label.acompanhamento') }} </v-card-title>
        <v-card-text>
          {{ $t('message.usuario_ativado_sem_fluxo', { perfilAprovador: nomPerfilAprovador, usuarioAprovador: nomUsuarioAprovador}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import {
  mapGetters,
} from 'vuex';
import moment from 'moment';
import { removeEmptyProperties } from '@/produto/common/functions/helpers';
import exportacao from '@/produto/common/functions/exportacao';
import { countRelatorioLogUsuario } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import { generateComputed } from '../../common/functions/roles-computed-generator';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';
import Status from '../../shared-components/Status';
import UsuarioAnexosDialog from './UsuarioAnexosDialog';
import UsuarioFornecedorFiltros from './UsuarioFornecedorFiltros';
import ModalDadosUltimaAlteracaoUsuario from '../../shared-components/ModalDadosUltimaAlteracaoUsuario';
import UsuarioAprovacaoDialog from './UsuarioAprovacaoDialog';

export default {
  components: {
    UsuarioFornecedorFiltros,
    UsuarioAnexosDialog,
    ModalDadosUltimaAlteracaoUsuario,
    UsuarioAprovacaoDialog,
    Acompanhamento,
    Status,
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      usuarioResources: this.$api.usuario(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      headers: [
        {
          text: this.$tc('label.acoes', 2), sortable: false, align: 'center', value: 'acoes',
        },

        {
          text: this.$t('label.expiracao'), sortable: false, align: 'center', value: 'icone_expiracao',
        },
        { text: this.$t('label.nome'), sortable: true, value: 'nome' },
        { text: this.$t('label.email'), sortable: true, value: 'email' },
        { text: this.$t('label.login'), sortable: true, value: 'nom_usuario' },
        { text: this.$tc('label.perfil', 1), value: 'nom_perfil' },
        { text: this.$tc('label.grupo_fornecedor', 1), value: 'grupofornecedor', sortable: true },
        { text: this.$tc('label.usuario_ultima_alteracao', 1), value: 'usuarioAlteracao', sortable: false },
        {
          text: this.$tc('label.tipo_fornecedor', 1), value: 'indUsuarioExternoMaster', sortable: true, cellClass: 'nowrap',
        },
        { text: this.$tc('label.procurador', 1), value: 'indProcurador', sortable: true },
        { text: this.$tc('label.anexo', 2), value: 'indPossuiAnexos', sortable: true },
        {
          text: this.$tc('label.status_aprovacao', 1), value: 'statusFluxo', sortable: true, align: 'center',
        },
        {
          text: this.$tc('label.ultima_alteracao_flag_ativacao', 1), value: 'usuarioAlteracaoFlagAtivoInativo', sortable: true, align: 'center',
        },
      ],
      workspace: {},
      entidadeWorkspace: 'gestao_usuario_fornecedor',
      pagination: {},
      totalPage: 0,
      esperar: false,
      registros: [],
      indSomenteUsuarioMaster: false,
      filtrosAplicados: {},
      indMinhasAprovacoesPendentes: false,
      bloquearExportacao: false,
      idPerfilAprovadorLegal: null,
      statusAprovacaoUsuario: '',
      titleJustificativaModal: '',
      usuarioAprovar: null,
      statusPassos: {},
      dialogAcompanhamentoSemFluxo: false,
      nomPerfilAprovador: '',
      nomUsuarioAprovador: '',
    };
  },
  props: {
    title: String,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar(true);
      },
      deep: true,
    },
    indSomenteUsuarioMaster() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar(false);
      }, 500);
    },
    paginationSize(valor) {
      this.$emit('PAGINACAO_ALTERANDO_TAMANHO_PAGINA', valor);
    },
    indMinhasAprovacoesPendentes() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar(false);
      }, 500);
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'implantacao',
      'indModoVarejo',
    ]),
    ...generateComputed('Usr', [
      'canAccessCRUD',
      'canSearch',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    isContentAvailable() {
      return !!this.tableData && !!this.tableData.length;
    },
    usuarioFilteredList() {
      return this.tableData.map((linha) => {
        const row = { ...linha };
        row.representante = {};
        row.representante.dependencias = this.stringToArray(linha);
        row.superioresImediatosArray = this.parseStringSuperioresImediatos(linha);
        row.canaisArray = this.parseStringCanal(linha);

        return row;
      });
    },
    filteredList() {
      return [];
    },
    isUsuarioLogadoExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    isUsuarioLogadoFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeVisualizarAnexos() {
      return this.canSearch;
    },
    podeCadastrarAnexos() {
      return this.canAccessCRUD && this.isUsuarioLogadoFuncionario;
    },
    cadastrarGestaoFornecedor() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_MASTER' || el === 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_REPRESENTANTE_LEGAL').length;
    },
    consultarGestaoFornecedor() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_CONSULTAR_GESTAO_DE_FORNECEDOR').length;
    },
    acessoRedefinirSenhaPadraoTradelinks() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'USR_RESET_SENHA_PADRAO_TRADELINKS').length;
    },
    isAprovadorRepresentanteLegal() {
      return this.usuarioLogado.idPerfil === parseInt(this.idPerfilAprovadorLegal, 10);
    },
  },
  methods: {
    onItemClass(...args) {
      const [usuario] = args;
      return !usuario.indAtivo ? 'TableRow--disabled' : '';
    },
    formatarData(valor) {
      return moment(valor).format('DD/MM/YYYY');
    },
    buscar(trocaPagina) {
      if (!trocaPagina) {
        this.pagination.page = 1;
      }
      const params = {
        ...this.filtrosAplicados,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      if (!this.isUsuarioLogadoFuncionario) {
        params.indUsuarioExterno = true;
      }

      if (params.colunaOrdenacao === 'nome') {
        params.colunaOrdenacao = 'u.nome';
      }

      if (params.colunaOrdenacao === 'indPossuiAnexos') {
        params.colunaOrdenacao = 'ind_possui_anexos';
      }

      if (params.colunaOrdenacao === 'indUsuarioExternoMaster') {
        params.colunaOrdenacao = 'fornecedor_master';
      }

      if (this.indSomenteUsuarioMaster) {
        params.indSomenteUsuarioMaster = this.indSomenteUsuarioMaster;
      }

      if (this.indMinhasAprovacoesPendentes) {
        params.indMinhasAprovacoesPendentes = this.indMinhasAprovacoesPendentes;
      }

      if (params.colunaOrdenacao === 'statusFluxo') {
        params.colunaOrdenacao = 'status_fluxo';
      }

      if (params.colunaOrdenacao === 'usuarioAlteracaoFlagAtivoInativo') {
        params.colunaOrdenacao = 'ind_ativo';
      }

      if (params.colunaOrdenacao === 'indProcurador') {
        params.colunaOrdenacao = 'ind_procurador';
      }

      params.indTabelaFornecedor = true;

      this.usuarioResources.listarUsuariosFornecedores(params).then((response) => {
        this.registros = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar(trocaPagina) {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar(trocaPagina);
      }, 1000);
    },
    montaTexto(item) {
      if (!item) {
        return '';
      }
      const array = item.split('|');
      return (`${array[2]}`) || '';
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    edit(row) {
      this.$emit('USUARIOTABELA_EDIT', row);
    },
    ver(row) {
      this.$emit('USUARIOTABELA_VER', row);
    },
    redefinirSenha(row) {
      this.$emit('USUARIOTABELA_RESET_SENHA', row);
    },
    redefinirSenhaPadraoTradelinks(row) {
      this.$emit('USUARIOTABELA_RESET_SENHA_PADRAO_TRADELINKS', row);
    },
    stringToArray(linha) {
      const canaisVendaRepresentanteObj = !linha.canaisVendaRepresentante ? []
        : linha.canaisVendaRepresentante.split(',').map((cat) => {
          const canaisVendaRepresentanteStr = cat.split(':');
          return {
            id: parseInt(canaisVendaRepresentanteStr[0], 10),
            nomExtensao: canaisVendaRepresentanteStr[1],
            indAtivo: canaisVendaRepresentanteStr[2],
          };
        });

      return {
        canaisVenda: canaisVendaRepresentanteObj,
      };
    },
    abrirModalAnexos(usuario) {
      this.$refs.usuarioAnexosDialog.open(usuario);
    },
    isUsuarioFornecedor(usuario) {
      return !!usuario.tipo && usuario.tipo === 'FORNECEDOR';
    },
    isUsuarioFornecedorMaster(usuario) {
      return usuario.indUsuarioExternoMaster;
    },
    podeEditar(usuario) {
      return this.canAccessCRUD && this.cadastrarGestaoFornecedor && (this.isUsuarioLogadoFuncionario
        || (this.isUsuarioFornecedor(this.usuarioLogado) && !this.isUsuarioFornecedorMaster(this.usuarioLogado)
        && this.isUsuarioFornecedorMaster(usuario)));
    },
    podeVisualizar(usuario) {
      return this.consultarGestaoFornecedor && !this.podeEditar(usuario);
    },
    buscaRelatorioLogUsuarioPorId(usuario) {
      const params = {
        id_usuario_selecionado: usuario.idUsuario ? usuario.idUsuario : usuario.id,
      };
      let quantidadeRegistros = 0;

      countRelatorioLogUsuario(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            this.requestExportacao(params, 'log_usuario_magalu', 'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestExportacao(params, tipoRelatorio, modulo = 'api') {
      const filtroTratado = removeEmptyProperties(params);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
    buscaDadosUltimaAlteracao(item) {
      const dados = {
        idUsuario: item.id,
      };
      setTimeout(() => this.$refs.modalDadosUltimaAlteracaoUsuario.open(dados), 2E2);
    },
    buscarChaveIDPerfilAprovadorLegal() {
      this.configSistemaResource.buscarChave({ chave: 'APROVADOR_USR_FORNE_REP_LEG' })
        .then((resp) => {
          this.idPerfilAprovadorLegal = resp.body;
        });
    },
    podeAprovarReprovarRepresentanteLegal(statusFluxo, indPossuiAnexos) {
      return this.isAprovadorRepresentanteLegal
        && statusFluxo && ['AGUARDANDO_APROVACAO', 'EM_ANALISE'].includes(statusFluxo) && indPossuiAnexos;
    },
    openModalJustificativaAprovacao(item, status, titleModal) {
      this.usuarioAprovar = item;
      this.titleJustificativaModal = titleModal;
      this.statusAprovacaoUsuario = status;
      this.statusAprovadoRepresentanteLegal = status;
      this.$refs.modalJustificativaAprovacao.open();
    },
    aprovarReprovarUsuario(justificativa) {
      const objArmazenamento = {
        ...this.usuarioAprovar,
      };
      objArmazenamento.statusFluxo = this.statusAprovacaoUsuario;
      objArmazenamento.justificativaAprovacao = justificativa;
      this.usuarioResources.aprovarUsuarioMaster({ id: objArmazenamento.id }, objArmazenamento)
        .then(() => {
          this.$router.push({ name: 'usuario-fornecedor' });
          this.filtrar(false);
          this.$toast(this.$t('message.atualizado_com_sucesso'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    mostrarAcompanhamento(item) {
      return item.statusFluxo && item.statusFluxo !== 'EM_CADASTRO' && item.statusFluxo !== 'CANCELADO';
    },
    aplicaFiltros(filtros = {}) {
      if (filtros !== {}) {
        this.filtrosAplicados = filtros;
        this.filtrar(false);
      }
    },
    limparWorkspaceFiltros(idUsuario) {
      const entidade = this.entidadeWorkspace;
      const params = {
        entidade,
        idUsuario,
      };
      this.workspaceFiltrosResources
        .limpar(params, params)
        .then(() => {
          this.$toast(this.$t('message.filtros_removidos'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento(idUsuario, item) {
      if (item.indAtivadoPeloAprovador) {
        this.nomPerfilAprovador = item.nomPerfilAprovador;
        this.nomUsuarioAprovador = item.nomUsuarioAprovador;
        this.dialogAcompanhamentoSemFluxo = true;
      } else {
        this.usuarioResources.buscarFluxoAprovacao({ idUsuario })
          .then((res) => {
            this.statusPassos = res.data;
            if (this.statusPassos.passos) {
              const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
              if (passo) {
                passo.isProximoPasso = true;
              }
            }
            setTimeout(() => this.$refs.modalAcompanhamento.open());
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    closeModal() {
      this.nomPerfilAprovador = '';
      this.nomUsuarioAprovador = '';
      this.dialogAcompanhamentoSemFluxo = false;
    },
    exibirIconeRepresentacaoConjuntoNaoAprovador(item) {
      return (this.isUsuarioLogadoFuncionario && item.indUsuarioExternoMaster && item.indRepresentacaoConjunto && !item.indAprovadorAnexoComercial)
        || (!this.isUsuarioLogadoFuncionario && item.indUsuarioExternoMaster && item.indRepresentacaoConjunto);
    },
    exibirIconeRepresentacaoConjuntoAprovador(item) {
      return item.indUsuarioExternoMaster && item.indRepresentacaoConjunto
        && item.indAprovadorAnexoComercial && this.isUsuarioLogadoFuncionario;
    },
  },
  mounted() {
    this.buscarChaveIDPerfilAprovadorLegal();
  },
};
</script>
